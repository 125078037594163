import gql from "graphql-tag";

const SUGGEST_LEFT_ARTICLES_QUERY = gql`
query Articles($id: ID!, $category_name: String! ) {
    articles(
        limit:1, 
        sort:"id:desc"
        where:{
            category: {name:$category_name},
            id_lt:$id
        }
        ) {
      id
      title
      category {
        name
      }
      ShortDescription
      image {
        url
      }
    }
  }
`;

export default SUGGEST_LEFT_ARTICLES_QUERY;
