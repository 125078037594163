/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import FormatAlignLeft from "@material-ui/icons/FormatAlignLeft";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// sections for this page
import SectionText from "./Sections/SectionText.js";
import SectionBlogInfo from "./Sections/SectionBlogInfo.js";
import SectionComments from "./Sections/SectionComments.js";
import SectionSimilarStories from "./Sections/SectionSimilarStories.js";

import blogPostPageStyle from "assets/jss/material-kit-pro-react/views/blogPostPageStyle.js";

const useStyles = makeStyles(blogPostPageStyle);

import PageFooter from "../commonElement/PageFooter.js";

//Content
import { useParams } from "react-router";
import Query from "../../components/Query";
import ReactMarkdown from "react-markdown";
import Moment from "react-moment";

import ARTICLE_QUERY from "../../queries/article/article";

export default function BlogPostPage() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  let { id } = useParams();

  return (
    <Query query={ARTICLE_QUERY} id={id}>
      {({ data: { article } }) => {
        const imageUrl =
          process.env.NODE_ENV !== "development"
            ? article.image.url
            : process.env.REACT_APP_BACKEND_URL + article.image.url;

        return (
          <div>
            <Header
              brand="Quilv.com"
              links={<HeaderLinks dropdownHoverColor="info" />}
              fixed
              color="transparent"
              changeColorOnScroll={{
                height: 300,
                color: "info",
              }}
            />
            <Parallax image={imageUrl} filter="dark">
              <div className={classes.container}>
                <GridContainer justify="center">
                  <GridItem md={8} className={classes.textCenter}>
                    <h1 className={classes.title}>
                      {article.title}
                    </h1>
                    <h4 className={classes.subtitle}>
                      {article.ShortDescription}
                    </h4>
                    <br />
                    <Button color="rose" size="lg" round>
                      <FormatAlignLeft /> Read Article
                    </Button>
                  </GridItem>
                </GridContainer>
              </div>
            </Parallax>
            <div className={classes.main}>
              <div className={classes.container}>
                <SectionText article={article}/>
                <SectionBlogInfo article={article}/>
                {  
                  
                  //<SectionComments />
                }
              </div>
            </div>
            <SectionSimilarStories id={id} category_name={article.category.name} />
            <PageFooter />
          </div>
        );
      }}
    </Query>
  );
}
