import gql from "graphql-tag";

const SUGGEST_RIGHT_ARTICLES_QUERY = gql`
query Articles($id: ID!, $category_name: String! ) {
    articles(
        limit:2, 
        sort:"id:asc"
        where:{
            category: {name:$category_name},
            id_gt:$id
        }
        ) {
      id
      title
      category {
        name
      }
      ShortDescription
      image {
        url
      }
    }
  }
`;

export default SUGGEST_RIGHT_ARTICLES_QUERY;
