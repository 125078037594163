import React from "react";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";

import Favorite from "@material-ui/icons/Favorite";

import Footer from "components/Footer/Footer.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

const PageFooter = () => {
    const classes = useStyles();
  return (
    <Footer
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.quilv.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.block}
                >
                  QuiLV
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a
                  href="https://www.quilv.com/about/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.block}
                >
                  About me
                </a>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <a href="https://www.blog.quilv.com/" className={classes.block}>
                  Blog
                </a>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            &copy; {1900 + new Date().getYear()} , made with{" "}
            <Favorite className={classes.icon} /> by{" "}
            <a href="https://www.quilv.com" target="_blank" rel="noopener noreferrer">
              QuiLV
            </a>{" "}
            for a better web.
          </div>
        </div>
      }
    />
  );
};

export default PageFooter;
