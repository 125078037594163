import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// import Quote from "components/Typography/Quote.js";

import ReactMarkdown from "react-markdown";
// core components

import sectionTextStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionTextStyle.js";

const useStyles = makeStyles(sectionTextStyle);

export default function SectionText(props) {
  const article = props.article;
  // console.log(article.listImageDemo);
  const classes = useStyles();
  const imgClasses = classNames(
    classes.imgRaised,
    classes.imgRounded,
    classes.imgFluid
  );

  function GetUri(uri){
    var url = uri.startsWith("http") ? uri : `${process.env.REACT_APP_BACKEND_URL}${uri}`;
    return url;
  }

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <ReactMarkdown 
            source={article.content}
            transformImageUri={uri => GetUri(uri)}
            escapeHtml={false} 
          />
        </GridItem>
        {
          typeof article.listImageDemo !== 'undefined' && article.listImageDemo.length > 0 ? (
          <GridItem xs={12} sm={10} md={10} className={classes.section}>
            <GridContainer>
              {article.listImageDemo.map((img, id) => {
                let imageUrl =
                  process.env.NODE_ENV !== "development"
                    ? img.url
                    : process.env.REACT_APP_BACKEND_URL + img.url;
                return (
                  <GridItem xs={12} sm={4} md={4} key={id}>
                    <img src={imageUrl} alt="..." className={imgClasses} />
                  </GridItem>
                );
              })}
            </GridContainer>
          </GridItem>
          ) : (<></>)
        }

        <GridItem xs={12} sm={8} md={8} className={classes.section}>
          <ReactMarkdown 
            source={article.Second_Content} 
            transformImageUri={uri => GetUri(uri)}
            escapeHtml={false} 
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
