import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import Info from "components/Typography/Info.js";

import sectionSimilarStoriesStyle from "assets/jss/material-kit-pro-react/views/blogPostSections/sectionSimilarStoriesStyle.js";

import Query from "../../../components/Query";
import CustomQuery from "../../../components/Query/CustomQuery.js";
import ARTICLE_QUERY from "../../../queries/article/article";
import SUGGEST_LEFT_ARTICLES_QUERY from "../../../queries/article/suggestLeftArticles";
import SUGGEST_RIGHT_ARTICLES_QUERY from "../../../queries/article/suggestRightArticles";

const useStyles = makeStyles(sectionSimilarStoriesStyle);

export default function SectionSimilarStories(props) {
  const classes = useStyles();
  const id = props.id;
  const category_name = props.category_name;

  const CardBlog = (props) => {
    const id = props.id;
    return (
      <Query query={ARTICLE_QUERY} id={id}>
        {({ data: { article } }) => {
          // console.log(article);
          if (article == null) return <></>;
          const imageUrl =
            process.env.NODE_ENV !== "development"
              ? article.image.url
              : process.env.REACT_APP_BACKEND_URL + article.image.url;
          return (
            <GridItem xs={12} sm={4} md={4}>
              <Card blog>
                <CardHeader image>
                  <a href={`/article/${article.id}`}>
                    <img src={imageUrl} alt="..." />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage: "url(" + imageUrl + ")",
                      opacity: "1",
                    }}
                  />
                </CardHeader>
                <CardBody>
                  <Info>
                    <h6>{article.category.name}</h6>
                  </Info>
                  <h4 className={classes.cardTitle}>
                    <a href={`/article/${article.id}`}>{article.title}</a>
                  </h4>
                  <p className={classes.description}>
                    {article.ShortDescription}
                    <a href={`/article/${article.id}`}> Read More </a>
                  </p>
                </CardBody>
              </Card>
            </GridItem>
          );
        }}
      </Query>
    );
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem md={12}>
            <h2 className={classes.title + " " + classes.textCenter}>
              Similar Projects
            </h2>
            <br />
            <GridContainer>
              {/** This for Get Suggest Left */}
              <CustomQuery
                query={SUGGEST_LEFT_ARTICLES_QUERY}
                id={id}
                category_name={category_name}
              >
                {({ data: { articles } }) => {
                  return typeof articles !== "undefined" && articles.length > 0 ?  <CardBlog id={articles[0].id} />
                  : <></>
                }}
              </CustomQuery>
              
              {/** This for Get Suggest SUGGEST_RIGHT_ARTICLES_QUERY */}
              <CustomQuery
                query={SUGGEST_RIGHT_ARTICLES_QUERY}
                id={id}
                category_name={category_name}
              >
                {
                  ({ data: { articles } }) => 
                  {
                    return typeof articles !== "undefined" && articles.length > 0 ? articles.map((article, index) => <CardBlog id={article.id} key={index}/>) : <></>
                  }
                }
              </CustomQuery>

            </GridContainer>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
