import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Web from "@material-ui/icons/Web";
import Apps from "@material-ui/icons/Apps";
import GetApp from "@material-ui/icons/GetApp";
import VideogameAsset from "@material-ui/icons/VideogameAsset";

// core components
import Header from "components/Header/Header.js";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import quilv from "assets/img/faces/quilv.jpg";
import myCV from "assets/QuiLe-cv.pdf"


import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

import Contact from "./contact.js";
import Query from "../../components/Query";
import PageFooter from "../commonElement/PageFooter.js";
import TabContentProject from "./TabContentProject.js";

import CATEGORIES_QUERY from "../../queries/category/categories";

const useStyles = makeStyles(profilePageStyle);

export default function ProfilePage({ ...rest }) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );

  return (
    <div>
      <Header
        color="transparent"
        brand="Quilv.com"
        links={<HeaderLinks dropdownHoverColor="info" />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "info",
        }}
        {...rest}
      />
      <Parallax
        image={require("assets/img/examples/city.jpg")}
        filter="dark"
        className={classes.parallax}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6}>
              <div className={classes.profile}>
                <div>
                  <img src={quilv} alt="..." className={imageClasses} />
                </div>
                <div className={classes.name}>
                  <h3 className={classes.title}>Qui Le</h3>
                  <h6>FULLSTACK DEVELOPER</h6>
                  <Button
                    justIcon
                    simple
                    color="dribbble"
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-dribbble"} />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="twitter"
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-twitter"} />
                  </Button>
                  <Button
                    justIcon
                    simple
                    color="pinterest"
                    className={classes.margin5}
                  >
                    <i className={classes.socials + " fab fa-pinterest"} />
                  </Button>
                </div>
              </div>
            </GridItem>
          </GridContainer>
          <div className={classNames(classes.description, classes.textCenter)}>
            <p>
              I'm Le Van Qui, FullStack Developer!
              <br />I can develop Web, Mobile App or Game.( Both Frontend & Backend).
              <br />I love to RnD new Technology, quickly adapt with new technology.
              <br />Hardworking, Learning Fast, Wide Knowledge. 
              <br />Alway Think Out of The Box to make everything is simpler, easier & faster.
            </p>
          </div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classNames(classes.textCenter)}>
              <Button color="primary" round  href={myCV} target="_blank" rel="noopener noreferrer">
                <GetApp className={classes.icons} /> Download CV (137kb)
              </Button>
            </GridItem>
          </GridContainer>
          <div className={classes.profileTabs}>
            <Query query={CATEGORIES_QUERY} id={null}>
              {({ data: { categories } }) => {
                let tabsData = [];
                categories.map((category, i) => {
                  let icon = Web;
                  switch (category.name){
                    case "Web":
                      icon = Web;
                      break;
                    case "Apps":
                      icon = Apps;
                      break;
                    case "Games":
                      icon = VideogameAsset;
                      break;
                    default:
                      icon = Web;
                      break;
                  }
                  let tab = {
                    tabButton: category.name,
                    tabIcon: icon,
                    tabContent: <TabContentProject id_category={category.id}/>
                  }
                  tabsData.push(tab);
                  return null;
                })
                return (
                  <NavPills
                    alignCenter
                    color="primary"
                    tabs={tabsData}
                  />
                );
              }}
            </Query>
          </div>
          <Clearfix />
        </div>
      </div>
      <Contact />
      <PageFooter/>
    </div>
  );
}
