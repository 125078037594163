import React from "react";
import ReactMarkdown from "react-markdown";

import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Badge from "components/Badge/Badge.js";

import Query from "../../components/Query";
import CardProjects from "./CardProjects.js";

import CATEGORY_ARTICLES_QUERY from "../../queries/category/articles";
import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

const TabContentProject = ({ id_category }) => {
  const classes = useStyles();
  return (
    <Query query={CATEGORY_ARTICLES_QUERY} id={id_category}>
      {({ data: { category } }) => {
        return (
          <GridContainer>
            <GridItem xs={12} sm={12} md={7} className={classes.gridItem}>
              <h4 className={classes.title}>{category.mainTitle}</h4>
              <GridContainer className={classes.collections}>
                <CardProjects articles={category.articles} />
              </GridContainer>
            </GridItem>
            <GridItem xs={12} sm={12} md={2} className={classes.gridItem}>
              <h4 className={classes.title}>{category.statusTitle}</h4>

              <ReactMarkdown
                className={classes.listUnstyled}
                source={category.descriptionStatus}
                escapeHtml={false}
              />

              <hr />
              <h4 className={classes.title}>{category.titleInformation}</h4>
              <ReactMarkdown
                className={classes.description}
                source={category.descriptionInformation}
                escapeHtml={false}
              />
              {category.TeachnicalTitle !== "" ? (
                <div>
                  <hr />
                  <h4 className={classes.title}>{category.TeachnicalTitle}</h4>
                  {category.ListTeachnical.map((teach, id) => (
                    <Badge color="primary" key={id}>
                      {teach.name}
                    </Badge>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </GridItem>
          </GridContainer>
        );
      }}
    </Query>
  );
};

export default TabContentProject;
