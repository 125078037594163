import gql from "graphql-tag";

const ARTICLE_QUERY = gql`
  query Articles($id: ID!) {
    article(id: $id) {
      id
      image {
        url
      }
      title
      ShortDescription
      content
      listImageDemo {
        url
      }
      Second_Content
      published_at
      category {
        id
        name
      }
      teachnicalTitle
      ListTechnical
    }
  }
`;

export default ARTICLE_QUERY;
