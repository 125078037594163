import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Badge from "components/Badge/Badge.js";

import profilePageStyle from "assets/jss/material-kit-pro-react/views/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

const CardProject = ({ article }) => {
  const classes = useStyles();
  const imageUrl =
    process.env.NODE_ENV !== "development"
      ? article.image.url
      : process.env.REACT_APP_BACKEND_URL + article.image.url;

  return (
    <Card
      background
      style={{
        backgroundImage: "url(" + imageUrl + ")",
      }}
    >
      <CardBody background className={classes.cardBody}>
        <Badge color="warning" className={classes.badge}>
          Spring 2016
        </Badge>
        <a href={`/article/${article.id}`}>
          <h2 className={classes.cardTitleWhite}>{article.title}</h2>
        </a>
      </CardBody>
    </Card>
  );
};

export default CardProject;
