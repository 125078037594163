import React from "react";

import GridItem from "components/Grid/GridItem.js";

import CardProject from "./CardProject.js";

const CardProjects = ({ articles}) =>{
    return(
        <>
        {
            articles.map((article,i) => {
                return (
                    <GridItem xs={12} sm={12} md={6} key={i}>
                        <CardProject article={article} key={`article__${article.id}`} />
                    </GridItem>
                );
            })
        }
        </>
    );
}

export default CardProjects;