import React from "react";

import { Switch, Route } from "react-router-dom";

// import Nav from "../../components/Nav";
// import Articles from "../Articles";
// import Article from "../Article";
// import Category from "../Category";
import ProfilePage from "../../pages/ProfilePage";
import BlogPostPage from "../../pages/BlogPostPage/BlogPostPage.js";

function App() {
  return (
    
      <Switch>
        <Route path="/" component={ProfilePage} exact />
        <Route path="/article/:id" component={BlogPostPage}/>
      </Switch>
    
    // <div className="App">
    //   <Nav />
    //   <Switch>
    //     <Route path="/" component={Articles} exact />
    //     <Route path="/article/:id" component={Article} exact />
    //     <Route path="/category/:id" component={Category} exact />
    //   </Switch>
    // </div>
  );
}

export default App;
