import gql from "graphql-tag";

const CATEGORIES_QUERY = gql`
  query Categories {
    categories(sort:"id:asc") {
      id
      name
    }
  }
`;

export default CATEGORIES_QUERY;
