import gql from "graphql-tag";

const CATEGORY_ARTICLES_QUERY = gql`
  query Category($id: ID!) {
    category(id: $id) {
      id
      name
      mainTitle
      statusTitle
      descriptionStatus
      titleInformation
      descriptionInformation
      TeachnicalTitle
      ListTeachnical
      articles(limit:4,sort:"id:desc") {
        id
        title
        content
        image {
          url
        }
        category {
          id
          name
        }
      }
    }
  }
`;

export default CATEGORY_ARTICLES_QUERY;
